define(['$window'], ($window) => {

  const imageSelectSlider = () => {
    const component = {};

    const _config = {
      selectors: {
        image: '.imageSelectCard_image',
        thumbnail: '.imageSelectCard_button',
        imageContainerSlider: '.imageSelectSlider_image_container_slider',
        text: '.imageSelectCard_text',
        thumbnailContainer: '.imageSelectSlider_thumbnail_container',
        textContainer: '.imageSelectSlider_text_container',
        viewMore: '.imageSelectSlider_thumbnail_container_viewMore',
        viewLess: '.imageSelectSlider_thumbnail_container_viewLess',
        viewContainer: '.imageSelectSlider_thumbnail_view_container',
        viewMoreClass: 'viewMore',
        slider: '.imageSelectSlider'
      },
      classNames: {
        active: 'active',
        display: 'display'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.slider = component.element.querySelector(component.config.selectors.slider);
      component.imageContainerSlider = component.element.querySelector(component.config.selectors.imageContainerSlider);
      component.thumbnailContainer = component.element.querySelector(component.config.selectors.thumbnailContainer);
      component.textContainer = component.element.querySelector(component.config.selectors.textContainer);
      component.images = component.element.querySelectorAll(component.config.selectors.image);
      component.thumbnails = component.element.querySelectorAll(component.config.selectors.thumbnail);
      component.viewMore = component.element.querySelector(component.config.selectors.viewMore);
      component.viewMoreClass = component.element.querySelector(component.config.selectors.viewMore);
      component.viewLess = component.element.querySelector(component.config.selectors.viewLess);
      component.viewContainer = component.element.querySelector(component.config.selectors.viewContainer);
      component.text = component.element.querySelectorAll(component.config.selectors.text);
      for (let i = 0; i < component.images.length; i++) {
        component.imageContainerSlider.appendChild(component.images[i])
      }
      for (let i = 0; i < component.thumbnails.length; i++) {
        component.thumbnailContainer.appendChild(component.thumbnails[i])
      }
      for (let i = 0; i < component.text.length; i++) {
        component.textContainer.appendChild(component.text[i])
      }
      component.image = component.element.querySelector(component.config.selectors.image);
      component.text[0].classList.add(component.config.classNames.active);
      component.thumbnails[0].classList.add(component.config.classNames.active);
      component.element.classList.add(component.config.classNames.display);
      component.addListeners();

      return component;
    };

    const _addListeners = () => {
      let i = 0;
      component.thumbnails.forEach(thumbnail => {
          let position = i;
          i++;
          thumbnail.addEventListener('click', () => {
            component.thumbnailClick(position);
          });
        }
      );
    }

    const _thumbnailClick = (position) => {
      let imageWidth = component.image.clientWidth;
      let sliderPosition = (-imageWidth * position);
      component.imageContainerSlider.style.transform = 'translateX(' + sliderPosition + 'px)';
      component.text.forEach(item => {
        item.classList.remove(component.config.classNames.active)
      })
      component.text[position].classList.add(component.config.classNames.active);
      component.thumbnails.forEach(thumbnail => {
        thumbnail.classList.remove(component.config.classNames.active)
      })
      component.thumbnails[position].classList.add(component.config.classNames.active);
    }

    component.config = _config;
    component.init = _init;
    component.addListeners = _addListeners;
    component.thumbnailClick = _thumbnailClick;

    return component;
  };

  return imageSelectSlider;
});
